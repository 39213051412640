.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h1, h2, h3, h4, h5, h6 {
  color: #444;
}

.Hero-background {
    background-image: url('/hero-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    min-height: 600px;

    text-align: left;
}

.Hero-title h1 {
  padding: 150px 0px 0px 0px;
}

/*
 * Very large viewport hero rules
 */
@media (min-width: 1367px) {
  .Hero-background {
    min-height: 900px;
  }
  .Hero-title h1 {
    padding: 250px 0px 0px 0px;
  }
}

/*
 * Mobile viewport hero rules
 */
@media (max-width: 575px) {
    .Hero-background {
      background-image: url('/hero-background-portrait.jpg');
      background-position: 100%;

      min-height: 600px;

      text-align: center;
    }
    .Hero-title h1 {
      padding: 25px 0px 0px 0px;
      text-align: center;
    }
}

.Registration-container {
  margin-top: 25px;
}

.Enquiry-container {
  margin-top: 25px;
}
